.sending-modal .ant-modal-content {
    right: 0;
    top: 0;
    width: 70%;
    position: fixed;
    max-height: 100vh; /* Defina a altura máxima desejada */
    overflow-y: auto; /* Adiciona barra de rolagem vertical quando necessário */
    padding: 40px 80px;
}

.sending-modal .ant-modal-title {
    font-size: 28px;
    margin-bottom: 20px; /* Reduzi a margem superior para compensar a mudança na altura */
}

.sending-modal .ant-modal-footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px; /* Ajustei a margem superior aqui também */
}

.sending-components .ant-form-item-row {
    display: flex;
    flex-direction: row;
}
