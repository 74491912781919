.custom-ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

@media (max-width: 800px) {
  .custom-ant-col {
    min-width: 358px !important;
    margin-bottom: 10px !important;
  }
}

.custom-ant-space {
  display: flex;
  flex-flow: wrap;
}

.custom-ant-space > .ant-space-item {
  width: 100%;
}
