:root {
  --white: #FFF;
  --purple: #884EA6;
  --blue: #6164C2;
  --strong-purple: #663b7c;
}

.desktop-menu {
  height: 100vh;
}

.desktop-menu > .ant-layout-sider-children {
  width: 140px;
  display: flex;
  flex-direction: column;
  background: #DBE8F4;
}

.desktop-menu-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 15px 0;
  gap: 10px;
  background-color: white;
  border-top-right-radius: 45px;
}

.desktop-menu-content {
  flex-grow: 1; /* Completa todo espaço em branco do componente pai */
  background: var(--purple);
  background: linear-gradient(180deg, var(--purple) 50%, var(--blue) 100%);
  padding: 20px 20px;
  border-bottom-right-radius: 45px;
  overflow-y: auto !important;
}

.desktop-menu-content-item {
  height: 60px !important;
  color: white !important;
  border-radius: 16px !important;
  margin-bottom: 20px !important;
  padding: 8px 0 0 0 !important;
}

.desktop-menu-content-item > .ant-menu-title-content {
  margin-inline-start: 0 !important;
}

.desktop-menu-content-item.ant-menu-item-selected {
  background: var(--strong-purple);
}

.desktop-menu-content-item.ant-menu-item:active {
  background: var(--strong-purple) !important;
}

.desktop-menu-content-subitem:hover {
  transition: 0.3s;
  opacity: 0.7;
}
