@media (max-width: 400px) {
  .full-size-mobile {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .ant-form > .ant-btn,
  .container-button-mobile > .ant-btn {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 12px;
  }

  .remove-element-mobile {
    display: none;
  }

  .row-to-col-mobile {
    flex-direction: column;
  }

  .full-max-size-mobile {
    max-width: 100% !important;
  }

  .negative-margin-top-mobile {
    margin-top: -50px;
  }

  .container-button-mobile {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .custom-tag-mobile {
    white-space: normal;
  }
}

.card-with-custom-padding > .ant-card-body {
  padding: 14px 16px !important;
}

/* Responsividade do layout */

@media (max-width: 1112px) {
  .header-user-name {
    display: none !important;
  }
}

@media (max-width: 514px) {
  .header-searcher {
    display: none !important;
  }
}

@media (max-width: 680px) {
  .desktop-menu {
    display: none !important;
  }

  .header-user-container {
    display: none !important;
  }

  .custom-header {
    margin: 12px 12px 0 12px !important;
  }

  .custom-content {
    margin: 0 12px 12px 12px !important;
  }
} 

@media (min-width: 681px) {
  .mobile-menu {
    display: none !important;
  }
}

.pdf-page {
  height: 500px !important;
  width: 200px !important;
  overflow: auto !important;
}

.interactive-item:hover {
  cursor: pointer;
  opacity: .7;
}
