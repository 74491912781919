@media (max-width: 600px) {
  .profile-form-row {
    display: flex;
    flex-direction: column; 
  }

  .profile-form-col {
    max-width: 100% !important;
  }

  .profile-field {
    margin-top: 0 !important;
  }

  .profile-avatar > .ant-row > .ant-col {
    margin-left: 0;
    text-align: center;
  }

  .profile-button {
    margin-top: 0 !important;
  }

  .profile-field-custom > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > input {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }

  .profile-field-custom > .ant-row > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > button {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .profile-form-row > .ant-col {
    flex: 100% !important;
  }
}
