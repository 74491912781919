@media (max-width: 600px) {
  .settings-form-row {
    display: flex !important;
    flex-direction: column !important;
  }

  .settings-form-col {
    max-width: 100% !important;
  }

  .settings-form-col > .ant-checkbox-wrapper {
    margin-top: 10px !important;
  }

  .settings-form-input {
    margin-bottom: 10px !important;
  }

  .settings-form-hidden-divider {
    display: none !important;
  }

  .settings-form-transfer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .settings-form-transfer > .ant-transfer-list {
    width: 100% !important;
  }

  .settings-form-transfer > .ant-transfer-operation {
    width: 25% !important;
    margin: 10px !important;
  }
}
